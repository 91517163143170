<template>
  <div v-loading="isLoading" class="project-group">
    <div class="headers">
      <div class="tabs">
        <div
          v-for="(item, i) in tabList"
          :key="i"
          class="tab-box"
          :class="{ active: i === activeColor }"
          @click="tabsChange(i, item)"
        >{{ item }}</div>
      </div>
      <el-button
        @click="createHandle"
      ><svg-icon icon-class="dir-add" />创建</el-button>
    </div>
    <div class="content-box">
      <el-table
        :data="tableData"
        style="width: 100%; height: auto"
        @row-click="rowClick"
      >
        <el-table-column prop="name" label="项目名称" align="center">
          <template #default="scope">
            <div v-if="scope.row === row && showInput === true">
              <el-input
                v-model="scope.row.name"
                size="mini"
                style="width: 100%; hight: 100%"
                @keyup.enter="handleKeyupSubmit(scope.row)"
              />
            </div>
            <div v-else>{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          :formatter="formatTime"
          show-overflow-tooltip
        />
        <el-table-column
          prop="number"
          align="center"
          label="成员数量"
          show-overflow-tooltip
        />
        <el-table-column
          v-if="activeColor==1"
          prop="account"
          align="center"
          label="项目owner"
          show-overflow-tooltip
        />
        <el-table-column
          v-if="activeColor==0"
          align="center"
          label="操作"
        >
          <template #default="scope">
            <div v-if="scope.row === row && showInput === true" style="cursor: pointer;" @click="cancleCreate">取消</div>
            <div v-else style="color:#165DFF;cursor: pointer;" @click="projectUsersHandle((scope.row))">
              项目组成员管理
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="total">合计：{{ totalPage }}</div>
        <el-pagination
          v-model:currentPage="pageNum"
          :page-size="pageSize"
          :page-count="pagenumber"
          :page-sizes="pageSizes"
          layout="sizes"
          :total="totalPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
        <div class="jumper">
          <div>{{ pageNum }}/{{ pagenumber }}</div>
          <div class="jumper-line" />
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            background
            layout="jumper"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
        <el-pagination
          v-model:currentPage="pageNum"
          :page-size="pageSize"
          :page-count="pagenumber"
          :page-sizes="pageSizes"
          background
          layout="prev, next"
          :total="totalPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- 项目组成员管理 -->
    <div class="dialog-container">
      <el-dialog
        v-model="dialogVisible"
        title="项目组成员管理"
        :close-on-click-modal="false"
        :before-close="handleCloseDialog"
        width="989px"
      >
        <div v-loading="isUserLoading" class="dialog-content">
          <div class="left">
            <div class="search-box">
              <el-input v-model="keyWord" placeholder="搜索" @input="searchHandle(keyWord)" />
            </div>
            <div class="table-box">
              <el-table
                :data="userData"
              >
                <el-table-column
                  align="center"
                  prop="memberNickname"
                  label="姓名"
                  show-overflow-tooltip
                />
                <el-table-column
                  prop="memberAccount"
                  align="center"
                  label="账号"
                  show-overflow-tooltip
                />
                <el-table-column
                  prop="memberMobile"
                  align="center"
                  label="手机号"
                  show-overflow-tooltip
                />
                <el-table-column
                  align="center"
                  label="操作"
                >
                  <template #default="scope">
                    <div style="color:#165DFF;cursor: pointer;" @click="delprojectUsersHandle((scope.row))">
                      移除
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="pagination">
                <div class="total">合计：{{ totalPageUser }}</div>
                <el-pagination
                  v-model:currentPage="pageNumUser"
                  :page-size="pageSizeUser"
                  :page-count="pagenumberUser"
                  :page-sizes="pageSizesUser"
                  layout="sizes"
                  :total="totalPageUser"
                  @size-change="handleSizeChangeUser"
                  @current-change="handleCurrentChangeUser"
                />
                <div class="jumper">
                  <div>{{ pageNumUser }}/{{ pagenumberUser }}</div>
                  <div class="jumper-line" />
                  <el-pagination
                    v-model:currentPage="pageNumUser"
                    :page-size="pageSizeUser"
                    :page-count="pagenumberUser"
                    :page-sizes="pageSizesUser"
                    background
                    layout="jumper"
                    :total="totalPageUser"
                    @size-change="handleSizeChangeUser"
                    @current-change="handleCurrentChangeUser"
                  />
                </div>
                <el-pagination
                  v-model:currentPage="pageNumUser"
                  :page-size="pageSizeUser"
                  :page-count="pagenumberUser"
                  :page-sizes="pageSizesUser"
                  background
                  layout="prev, next"
                  :total="totalPageUser"
                  @size-change="handleSizeChangeUser"
                  @current-change="handleCurrentChangeUser"
                />
              </div>
            </div>
          </div>
          <div class="right">
            <div v-if="!isSearch" class="query-box">
              <div>
                <el-input v-model="searchUsers" placeholder="输入账号或者手机号搜索" />
                <el-button :loading="queryBtnLoading" type="primary" @click="queryUsers">查询</el-button>
              </div>
            </div>
            <div v-else style="height:100%;">
              <div class="search-box">
                <el-input v-model="searchUsers" placeholder="输入账号或者手机号搜索">
                  <template #append>
                    <div @click="queryUsers">
                      <el-icon><Search /></el-icon>搜索
                    </div>
                  </template>
                </el-input>
              </div>
              <div v-if="userList.length===0" class="no-data">
                <div class="box">
                  <img src="@/assets/icon/no-search.png" alt="暂无数据">
                  <div class="no-data-text">暂无数据</div>
                </div>
              </div>
              <div v-for="(item, i) in userList" :key="i" class="user-list-box">
                <div style="display:flex;align-items: center;">
                  <img :src="item.avatar" :alt="item.account">
                  <div>
                    <div class="text">{{ item.account }}</div>
                    <div class="text">{{ item.mobile }}</div>
                  </div>
                </div>
                <el-button :disabled="addBtnLoading" type="primary" @click="addUsers(item)">添加</el-button>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getUserProjectGroup, getUserOwnerProjectGroup, getUserMemberProjectGroup, addProjectGroup, queryProjectGroupInfo, queryActiveUser, updateProjectGroup } from '@/api/ai'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  data() {
    return {
      row: '',
      showInput: false,
      isLoading: false,
      activeColor: 0,
      tabList: ['我创建的项目组', '我加入的项目组'],
      tableData: [],
      pageSize: 10,
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [5, 10, 20, 30],
      dialogVisible: false,
      projectGroupId: null,
      userData: [],
      keyWord: '',
      isUserLoading: false,
      pageSizeUser: 10,
      pageNumUser: 1,
      pagenumberUser: 0,
      totalPageUser: 0,
      pageSizesUser: [5, 10, 20, 30],
      searchUsers: '',
      queryBtnLoading: false,
      addBtnLoading: false,
      isSearch: false,
      userList: [],
      isAdd: false
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    tabsChange(i) {
      this.activeColor = i
      this.pageNum = 1
      this.isAdd = false
      this.$nextTick(() => {
        this.getData()
      })
    },
    // 项目组成员管理
    projectUsersHandle(row) {
      this.projectGroupId = row.id
      this.dialogVisible = true
      this.$nextTick(() => {
        this.queryProjectGroupInfo()
      })
    },
    // 弹框关闭
    handleCloseDialog() {
      this.dialogVisible = false
      this.isSearch = false
      this.searchUsers = ''
      this.keyWord = ''
    },
    // 项目成员管理分页信息
    handleSizeChangeUser(val) {
      this.pageSizeUser = val
      this.pageNumUser = 1
      this.queryProjectGroupInfo()
    },
    handleCurrentChangeUser(val) {
      this.pageNumUser = val
      this.queryProjectGroupInfo()
    },
    searchHandle(val) {
      this.keyWord = val
      this.queryProjectGroupInfo()
    },
    // 查询用户
    queryUsers() {
      this.queryBtnLoading = true
      this.isUserLoading = true
      if (this.searchUsers.trim() === '') {
        ElMessage.warning('请输入账号或者手机号查询！')
        this.queryBtnLoading = false
        this.isUserLoading = false
        this.userList = []
      } else {
        queryActiveUser(this.searchUsers).then((response) => {
          this.userList = response.data.content
          this.isSearch = true
          this.queryBtnLoading = false
          this.isUserLoading = false
        }).catch(() => {
          this.isUserLoading = false
          this.queryBtnLoading = false
        })
      }
    },
    addUsers(item) {
      const bindUser = []
      this.addBtnLoading = true
      bindUser.push(item.uId)
      const data = {
        bindUserList: bindUser,
        unBindUserList: []
      }
      const params = {
        projectGroupID: this.projectGroupId
      }
      updateProjectGroup(data, params).then((response) => {
        if (response.meta.status === 201) {
          ElMessage({
            message: '添加成功',
            type: 'success'
          })
          this.pageNumUser = 1
          this.queryProjectGroupInfo()
          this.pageNum = 1
          this.getData()
          this.addBtnLoading = false
          this.searchUsers = ''
          this.isSearch = false
        } else {
          this.addBtnLoading = false
          this.searchUsers = ''
          this.isSearch = false
        }
      }).catch(() => {
        this.addBtnLoading = false
        this.searchUsers = ''
        this.isSearch = false
      })
    },
    // 移除项目组人员
    delprojectUsersHandle(item) {
      const unbindUser = []
      unbindUser.push(item.memberid)
      const data = {
        bindUserList: [],
        unBindUserList: unbindUser
      }
      const params = {
        projectGroupID: this.projectGroupId
      }
      ElMessageBox.confirm('确定要将该人员移除吗？', '移除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
        // type: "warning",
      })
        .then(() => {
          updateProjectGroup(data, params).then((response) => {
            if (response.meta.status === 201) {
              ElMessage({
                message: '移除成功',
                type: 'success'
              })
              this.pageNumUser = 1
              this.queryProjectGroupInfo()
              this.pageNum = 1
              this.getData()
            } else {
              ElMessage({
                message: response.meta.msg,
                type: 'error'
              })
            }
          })
        })
        .catch(() => {})
    },
    // 获取项目组成员基本信息
    queryProjectGroupInfo() {
      this.isUserLoading = true
      const data = {
        projectGroupID: this.projectGroupId,
        pagenum: this.pageNumUser,
        pagesize: this.pageSizeUser,
        keyWord: this.keyWord
      }
      queryProjectGroupInfo(data).then((response) => {
        this.userData = response.data.content
        this.pagenumberUser = response.data.totalpages
        this.totalPageUser = response.data.totalelements
        this.isUserLoading = false
      }).catch(() => {
        this.isUserLoading = false
      })
    },
    // 创建
    createHandle() {
      this.isAdd = true
      if (this.activeColor === 1) {
        this.activeColor = 0
      }
      this.$nextTick(() => {
        this.getData()
        // this.showInput = true
        // this.row = { name: '', number: 0, createTime: new Date() }
        // this.tableData.unshift(this.row)
      })
    },
    // 取消
    cancleCreate() {
      this.tableData.splice(0, 1)
      this.showInput = false
    },
    handleKeyupSubmit(row) {
      if (row.name === '') {
        this.tableData.splice(0, 1)
        this.showInput = false
      } else {
        const regExp = /^[\u4e00-\u9fa5a-z-_.A-Z0-9]+$/
        if (regExp.test(row.name.trim()) === false) {
          ElMessage.warning('项目组名称仅可以包含中英文数字-_.')
        } else if (row.name.trim().length < 2 || row.name.trim().length > 50) {
          ElMessage.warning('项目组名称长度应在2-50个字符之间')
        } else {
          const data = {
            projectGroupName: this.row.name.trim(),
            ownerID: 0,
            memberID: []
          }
          addProjectGroup(data).then((response) => {
            if (response.meta.status === 201) {
              ElMessage({
                message: '创建成功',
                type: 'success'
              })
              this.isAdd = false
              this.getData()
              this.showInput = false
            } else {
              this.showInput = false
            }
          }).catch(() => {
            this.showInput = false
          })
        }
      }
    },
    rowClick(row, column) {
      // if (this.tableData[0].name !== row.name) {
      //   this.showInput = false
      //   this.tableData.splice(0, 1)
      // }
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.isAdd = false
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.isAdd = false
      this.getData()
    },
    getData() {
      this.tableData = []
      const data = {
        pagenum: this.pageNum,
        pagesize: this.pageSize
      }
      this.isLoading = true
      if (this.activeColor == 0) {
        getUserOwnerProjectGroup(data).then((response) => {
          this.tableData = response.data.content
          this.pagenumber = response.data.totalpages
          this.totalPage = response.data.totalelements
          this.isLoading = false
          if (this.isAdd) {
            this.$nextTick(() => {
              this.showInput = true
              this.row = { name: '', number: 0, createTime: new Date() }
              this.tableData.unshift(this.row)
            })
          }
        }).catch(() => {
          this.isLoading = false
        })
      } else {
        getUserMemberProjectGroup(data).then((response) => {
          this.tableData = response.data.content
          this.pagenumber = response.data.totalpages
          this.totalPage = response.data.totalelements
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
        })
      }
    },
    // 时间格式化
    formatTime(row, column) {
      if (!row.createTime) {
        return
      }
      const data = row[column.property]
      const dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hour = dtime.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      let minute = dtime.getMinutes()
      if (minute < 10) {
        minute = '0' + minute
      }
      let second = dtime.getSeconds()
      if (second < 10) {
        second = '0' + second
      }
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      )
    }
  }
}
</script>

<style lang="less" scoped>
.project-group{
  padding: 16px 30px 24px;
  height: 100%;
  width: 100%;
  overflow: auto;
  font-family: PingFang SC-Regular, PingFang SC;
  ::v-deep .el-loading-mask{
    z-index: 9;
  }
  ::v-deep{
    .pagination .jumper .el-input, .pagination .jumper .el-input__inner{
      box-shadow: none;
      background-color: transparent;
    }
  }
  ::v-deep .el-dialog{
    max-height: none;
    .el-dialog__body{
      max-height: none;
    }
  }
  .dialog-container{
    ::v-deep .el-dialog__body{
      max-height: 640px;
    }
    .search-box{
          width: 271px;
          margin-bottom: 8px;
          ::v-deep .el-icon{
            color: #fff;
            vertical-align: middle;
            margin-bottom: 3px;
            svg{
              width: 14px;
              height: 14px;
            }
          }
          ::v-deep .el-input__wrapper{
            box-shadow: none;
          }
          ::v-deep .el-input-group__append{
            box-shadow: none;
            background-color: #437AEC;
            color: #fff;
            padding: 0 13px;
          }
        }
    ::v-deep .el-icon{
      color: #595F67;
    }
    ::v-deep .el-dialog__body{
      padding: 16px;
    }
    .dialog-content{
      display: flex;
      justify-content: space-between;
      .left{
        width: 636px;
        background: #F5F7FA;
        border-radius: 6px;
        border: 1px solid #CFD3E3;
        padding: 16px 16px 24px;
        box-sizing: border-box;
        max-height: 608px;
        .table-box{
          padding: 16px 30px 28px;
          background: #FFFFFF;
          border-radius: 6px;
          max-height: 526px;
          overflow: auto;
          ::v-deep .el-table{
            th.el-table__cell{
              background-color: #F5F7FA;
            }
            tr{
              background-color: #F5F7FA;
              td {
                border-bottom: none;
                .cell{
                  color: #303133;
                }
              }
            }
            .cell{
              color: #909399;
            }
          }
        }
      }
      .right{
        max-height: 608px;
        overflow: auto;
        font-size: 14px;
        color: #000;
        width: 304px;
        background: #F5F7FA;
        border-radius: 6px;
        border: 1px solid #CFD3E3;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        padding: 16px;
        box-sizing: border-box;
        ::v-deep .el-button{
            color: #fff;
          }
        .query-box{
          height: 100%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          ::v-deep .el-button{
            padding: 8px 17px;
            margin-top: 24px;
          }
        }
        .user-list-box{
          margin-left: 12px;
          margin-right: 10px;
          padding: 12px 0;
          border-bottom: 1px solid #E7E7E7;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .text{
            height: 22px;
            line-height: 22px;
            width: 121px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          img{
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-right: 8px;
          }
        }
        .no-data{
          height: calc(100% - 34px);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          text-align: center;
          color: #909399;
          .box {
            position: relative;
          }
          img{
            width: 256px;
            height: 256px;
            margin-bottom: 50px;
          }
          .no-data-text{
            position: absolute;
            top: 234px;
            left: 100px;
          }
        }
        ::v-deep .el-icon{
          color: #fff;
          font-size: 12px;
        }
        ::v-deep .el-input{
          height: 34px;
          line-height: 34px;
        }
        ::v-deep .el-input__inner{
          height: 34px;
          line-height: 34px;
        }
        ::v-deep .el-input__wrapper{
          box-shadow: none;
        }
      }
    }
  }
  .headers{
    display: flex;
    justify-content: space-between;
    ::v-deep .el-button{
      background-color: #437AEC;
      color: #fff;
      padding: 10px 15px;
    }
    .svg-icon{
      margin-right: 5px;
      margin-bottom: 1px;
    }
    .tabs{
      display: flex;
      padding-bottom: 8px;
      .tab-box{
        width: 144px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        background: #F2F3F5;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        color: #606266;
      }
      .active{
        background: #437AEC;
        color: #fff;
      }
    }
  }
  .content-box{
    background: #F5F7FA;
    border-radius: 6px;
    border: 1px solid #CFD3E3;
    padding: 8px 31px 31px;
    ::v-deep .el-table tr{
      background-color: #F5F7FA;
    }
    ::v-deep .el-table{
      z-index: 0;
      background-color: #F5F7FA;
      .cell{
        color: #909399;
        font-size: 14px;
        .el-input{
          width: 100px !important;
        }
        .el-input__wrapper {
          height: 20px;
        }
      }
    }
    .pagination{
      ::v-deep .el-input__wrapper{
        background-color: #F5F7FA;
      }
    }
  }
}
</style>
